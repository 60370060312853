<template>
  <transition name="modal-v">
    <div class="modal-popup">
      <div class="modal-popup__container">
        <div class="modal-popup__content">
          <div class="modal-popup__title">
            Добро пожаловать на az-most.ru информационный интернет-портал для
            российских специалистов в области здравоохранения!
          </div>

          <div class="modal-popup__body">
            <div class="body-popup">
              <div class="body-popup__container">
                <div class="body-popup__text text-bold">
                  Соглашение о получении информации о лекарственных препаратах,
                  отпускаемых по рецепту врача
                </div>
                <div class="body-popup__text text-normal">
                  В данном разделе сайта компания ООО «АстраЗенека
                  Фармасьютикалз» (далее АстраЗенека) представляет информацию
                  о препаратах, отпускаемых по рецепту врача, их свойствах,
                  способах применения и возможных противопоказаниях
                  исключительно специалистам здравоохранения (медицинские
                  и фармацевтические работники).
                </div>
                <div class="body-popup__text text-normal">
                  В случае, если вы не являетесь специалистом здравоохранения,
                  но в нарушение настоящего условия подписываете данное
                  соглашение, компания АстраЗенека не несёт ответственности
                  за возможные отрицательные последствия, возникшие в результате
                  самостоятельного использования вами информации с сайта,
                  без предварительной консультации со специалистом
                  здравоохранения. Вы делаете это самостоятельно и осознанно,
                  понимая, что применение рецептурных препаратов возможно только
                  после предварительной консультации со специалистом
                  здравоохранения.
                </div>
                <div class="body-popup__text text-bold">
                  Настоящим я подтверждаю, что являюсь специалистом
                  здравоохранения и подтверждаю своё согласие с тем,
                  что применение лекарственных препаратов, отпускаемых
                  по рецепту врача, возможно только после предварительной
                  консультации со специалистом.
                </div>
                <div class="body-popup__text text-normal">
                  Если вы не согласны с данным соглашением, то, к сожалению,
                  мы не можем предоставить вам доступ к информации данного
                  сайта.
                </div>
              </div>
            </div>
          </div>

          <div class="modal-popup__btn">
            <a
              class="
                modal-popup__btn-undo
                d-md-inline-flex
                button button_empty-pink
              "
              href="https://www.astrazeneca.ru/"
            >
              Отказаться
            </a>
            <div
              class="
                modal-popup__btn-accept
                d-md-inline-flex
                button button_pink
              "
              @click="closeModal"
            >
              Подтвердить
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { bus } from '@/main'
export default {
  name: "ModalPopup",
  data: () => ({}),
  methods: {
    closeModal() {
      this.$emit("close", false);
      bus.$emit('scrollLock', false)
    },
  },
  mounted() {
   bus.$emit('scrollLock', true)
  },
};
</script>

<style lang="scss" scoped>
.modal-v-enter-active,
.modal-v-leave-active {
  transition: all 0.5s ease;
}
.modal-v-enter,
.modal-v-leave-to {
  opacity: 0;
  transform: translateY(0);
}

.modal-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(31, 31, 31, 0.6);
  transition: opacity 0.3s ease;
  z-index: 99999;
  &__container {
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
    display: flex;
    @media screen and (max-width: 767px) {
      padding: 68px 16px;
    }
  }
  &__content {
    width: 100%;
    // max-height: 90vh;
    height: fit-content;
    max-width: 800px;
    margin: auto;
    padding: 48px;
    color: #000;
    background-color: #fff;
    box-sizing: border-box;
    transform: translate(0, 0);
    transition: all 0.3s ease;
    // overflow-y: auto;
    @media screen and (max-width: 1220px) {
      max-width: 592px;
      margin: 36px auto;
    }
    @media screen and (max-width: 767px) {
      // height: 100%;
      margin: 0;
      justify-content: flex-start;
      padding: 24px;
    }
  }
  &__title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #830051;
    @media screen and (max-width: 767px) {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }
  &__body {
    .body-popup {
      &__text {
        margin-bottom: 16px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 22px;
        &.text-bold {
          font-weight: 500;
        }
        &.text-normal {
          font-weight: 400;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__btn {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &-undo {
      margin-right: 16px;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        margin-top: 12px;
      }
    }
    &-accept {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
}
</style>