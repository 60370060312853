export default ({
  state: {
    slides: [],
    nosologys: [],
    nosologyDetail: {},
    subgroupDetail: {},
    disease: {},
    pageVerify: [],
    events: [],
    eventDetail: {},
    textList: null,
    epicentralList: null,
    videoList: null,
    podcastsList: null,
    textDetail: {},
    epicentralDetail: {},
    videoDetail: {},
    podcastDetail: {},
    filteredEvents: [],
    medications: [],
    medicationDetail: {},
    projects: [],
    archiveEvents: [],
    tabs: []
  },
  getters: {
    Slides: s => s.slides,
    Nosologys: s => s.nosologys,
    NosologyDetail: s => s.nosologyDetail,
    SubgroupDetail: s => s.subgroupDetail,
    Disease: s => s.disease,
    pageVerify: s => s.pageVerify,
    Events: s => s.events,
    FilteredEvents: s => s.filteredEvents,
    EventDetail: s => s.eventDetail,
    TextDetail: s => s.textDetail,
    EpicentralDetail: s => s.epicentralDetail,
    TextList: s => s.textList,
    EpicentralList: s => s.epicentralList,
    VideoList: s => s.videoList,
    PodcastsList: s => s.podcastsList,
    VideoDetail: s => s.videoDetail,
    PodcastDetail: s => s.podcastDetail,
    Medications: s => s.medications,
    MedicationDetail: s => s.medicationDetail,
    Projects: s => s.projects,
    ArchiveEvents: s => s.archiveEvents,
    Tabs: s => s.tabs
  },
  mutations: {
    updateSlides(s, slides) {
      s.slides = slides
    },
    updateNosologys(s, nosologys) {
      s.nosologys = nosologys
    },
    updateNosologyDetail(s, nosologyDetail) {
      s.nosologyDetail = nosologyDetail
    },
    updateSubgroupDetail(s, subgroupDetail) {
      s.subgroupDetail = subgroupDetail
    },
    updateDisease(s, disease) {
      s.disease = disease
    },
    updatePageVerify(s, verify) {
      s.pageVerify = verify
    },
    updateEvents(s, events) {
      let arr = []
      events.passed.forEach(item => {
        item.state = 'passed'
      })
      arr = arr.concat(events.active)
      arr = arr.concat(events.today)
      arr = arr.concat(events.tomorrow)
      arr = arr.concat(events.next)
      arr = arr.concat(events.passed)
      s.events = arr
    },
    updateFilteredEvents(s, events) {
      let arr = []
      events.passed.forEach(item => {
        item.state = 'passed'
      })
      arr = arr.concat(events.active)
      arr = arr.concat(events.today)
      arr = arr.concat(events.tomorrow)
      arr = arr.concat(events.next)
      // arr = arr.concat(events.passed)
      s.filteredEvents = arr
    },
    updateEventDetail(s, event) {
      s.eventDetail = event
    },
    updateTextDetail(s, material) {
      s.textDetail = material
    },
    updateEpicentralDetail(s, material) {
      s.epicentralDetail = material
    },
    updateVideoDetail(s, material) {
      s.videoDetail = material
    },
    updateTextList(s, list) {
      s.textList = list
    },
    updateEpicentralList(s, list) {
      s.epicentralList = list
    },
    updateVideoList(s, list) {
      s.videoList = list
    },
    updatePodcastsList(s, list) {
      s.podcastsList = list
    },
    updatePodcastDetail(s, material) {
      s.podcastDetail = material
    },
    updateMedications(s, medications) {
      s.medications = medications
    },
    updateMedicationDetail(s, medication) {
      s.medicationDetail = medication
    },
    updateProjects(s, projects) {
      s.projects = projects
    },
    updateArchiveEvents(s, archiveEvents) {
      s.archiveEvents = archiveEvents
    },
    updateTabs(s, tabs) {
      s.tabs = tabs
    },
  },
  actions: {
    async fetchSlides(ctx) {
      return await this.$axios({
        method: 'GET',
        headers: {
          Authorization: null,
        },
        url: '/api/content/banner/',
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateSlides', result)
          return true

        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },

    async fetchNosologys(ctx) {
      return await this.$axios({
        method: 'GET',
        headers: {
          Authorization: null,
        },
        url: '/api/content/nosology/',
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateNosologys', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },

    async fetchNosologyDetail(ctx, obj) {
      ctx.commit('updateNosologyDetail', {})
      let params = ''
      if (obj.access) {
        params = `?access=${obj.access}`
      }
      return await this.$axios({
        method: 'GET',
        // headers: {
        //   Authorization: null,
        // },
        url: `/api/content/nosology/${obj.slug}/${params}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateNosologyDetail', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          if (error && error.request && error.request.status === 404) {
            ctx.commit('updateNosologyDetail', 404)
          } else if(error.code !== 'ERR_CANCELED'){
            ctx.commit('updateNosologyDetail', false)
          }

          return false
        });
    },

    async fetchSubgroupDetail(ctx, obj) {
      ctx.commit('updateSubgroupDetail', {})
      let params = ''
      if (obj.access) {
        params = `?access=${obj.access}`
      }
      return await this.$axios({
        method: 'GET',
        // headers: {
        //   Authorization: null,
        // },
        url: `/api/content/nosology/${obj.nos_slug}/subgroup/${obj.subgroup_slug}/${params}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateSubgroupDetail', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          if (error && error.request && error.request.status === 404) {
            ctx.commit('updateSubgroupDetail', 404)
            return 404
          } else {
            ctx.commit('updateSubgroupDetail', false)
          }

          return false
        });
    },

    async fetchDisease(ctx, { disease, access }) {
      ctx.commit('updateDisease', {})
      let params = ''
      if (access) {
        params = `?access=${access}`
      }
    
      return await this.$axios({
        method: 'GET',
        url: `/api/content/nosology/desease/${disease}/${params}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateDisease', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          if (error.request.status === 404) {
            ctx.commit('updateDisease', 404)
          } else {
            ctx.commit('updateDisease', false)
          }

          return false
        });
    },
    async fetchPageVerify(ctx, url) {
      return await this.$axios({
        method: 'GET',
        url: `/api/content/pages/?url=${url}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updatePageVerify', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchEvents(ctx, filter) {
      let q = '?group=1'
      if (filter) {
        for (let key in filter) {
          let str = ''
          if (filter[key].length) {
            filter[key].forEach((i, ind) => {
              if (i) {
                str = ''
                str += `&${key}=${i}`
                q += str
              }

            })
          }
        }
        q = q
      }

      return await this.$axios({
        method: 'GET',
        headers: {
          Authorization: null,
        },
        url: `/api/event/${q}`,
      })
        .then(async (response) => {
          const result = response.data;
          if (filter) {
            if (Object.keys(filter).includes('date_before') || Object.keys(filter).includes('date_after')) {
              ctx.commit('updateFilteredEvents', result)
            } else {
              ctx.commit('updateEvents', result)
              ctx.commit('updateFilteredEvents', result)
            }
          } else {
            ctx.commit('updateEvents', result)
            ctx.commit('updateFilteredEvents', result)
          }

          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchEventsCalendar(ctx, id) {
      return await this.$axios({
        method: 'GET',
        headers: {
          Authorization: null,
        },
        url: `/api/event/${id}/ics/`,
      })
        .then(async (response) => {
          const result = URL.createObjectURL(new Blob([await response.data]));
          return result
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchEventDetail(ctx, obj) {
      let params = ''
      if (obj.access) {
        params = `?access=${obj.access}`
      }
      return await this.$axios({
        method: 'GET',
        // headers: {
        //   Authorization: null,
        // },
        url: `/api/event/${obj.id}/${params}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateEventDetail', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          if (error.request.status === 404) {
            ctx.commit('updateEventDetail', 404)
          } else {
            ctx.commit('updateEventDetail', false)
          }

          return false
        });
    },
    async fetchMedications(ctx, filter) {
      let q = ''
      if (filter && Object.keys(filter).length) {
        for (let key in filter) {
          let str = ''
          if (filter[key].length) {
            filter[key].forEach((i, ind) => {
              if (i) {
                str = ''
                str += `&${key}=${i}`
                q += str
              }

            })
          }
        }
        q = "?" + q
      }
      return await this.$axios({
        method: 'GET',
        url: `/api/content/drugs/${q}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateMedications', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchMedicationFavorite(ctx, {method, slug, filter,  favoritePage = false}) {
      return await this.$axios({
        method: method,
        url: `/api/content/drugs/${slug}/favorite/`,
      })
        .then(async (response) => {
          const result = response.data;
          if(!favoritePage) {
            ctx.dispatch('fetchMedications', filter)
          }
          if(method === "POST") {
            ctx.dispatch('fetchAlerts', {alerts: 'Препарат добавлен в избранное', type: 'notify'})
          } else {
            ctx.dispatch('fetchAlerts', {alerts: 'Препарат удален из избранного', type: 'notify'})
          }
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchMedicationWatchLater(ctx, {method, slug, filter,  favoritePage = false}) {
      return await this.$axios({
        method: method,
        url: `/api/content/drugs/${slug}/watch-later/`,
      })
        .then(async (response) => {
          const result = response.data;
          if(!favoritePage) {
            ctx.dispatch('fetchMedications', filter)
          }
          if(method === "POST") {
            ctx.dispatch('fetchAlerts', {alerts: 'Препарат добавлен в "Смотреть позже"', type: 'notify'})
          } else {
            ctx.dispatch('fetchAlerts', {alerts: 'Препарат удален из "Смотреть позже"', type: 'notify'})
          }
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchMedicationHistory(ctx, slug) {
      return await this.$axios({
        method: "POST",
        url: `/api/content/drugs/${slug}/view/`,
      })
        .then(async (response) => {
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchMedicationDetail(ctx, obj) {
      let params = ''
      if (obj.access) {
        params = `?access=${obj.access}`
      }
      return await this.$axios({
        method: 'GET',
        // headers: {
        //   Authorization: null,
        // },
        url: `/api/content/drugs/${obj.slug}/${params}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateMedicationDetail', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          if (error.request.status === 404) {
            ctx.commit('updateMedicationDetail', 404)
          } else {
            ctx.commit('updateMedicationDetail', false)
          }

          return false
        });
    },
    async fetchTextMaterials(ctx, filter) {
      let q = ''
      if (filter  && Object.keys(filter).length) {
        for (let key in filter) {
          let str = ''
          if (filter[key].length) {
            filter[key].forEach((i, ind) => {
              if (i) {
                str = ''
                str += `&${key}=${i}`
                q += str
              }

            })
          }
        }
        q = "?" + q
      }
      ctx.commit('updateTextList', null)
      return await this.$axios({
        method: 'GET',
        url: `/api/library/text/${q}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateTextList', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchProjectFavorite(ctx, {method, id, filter,  favoritePage = false}) {
      return await this.$axios({
        method: method,
        url: `/api/projects/${id}/favorite/`,
      })
        .then(async (response) => {
          const result = response.data;
          if(!favoritePage) {
            ctx.dispatch('fetchProjects', filter)
          }
          if(method === "POST") {
            ctx.dispatch('fetchAlerts', {alerts: 'Проект добавлен в избранное', type: 'notify'})
          } else {
            ctx.dispatch('fetchAlerts', {alerts: 'Проект удален из избранного', type: 'notify'})
          }
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchProjectWatchLater(ctx, {method, id, filter,  favoritePage = false}) {
      return await this.$axios({
        method: method,
        url: `/api/projects/${id}/watch-later/`,
      })
        .then(async (response) => {
          const result = response.data;
          if(!favoritePage) {
            ctx.dispatch('fetchProjects', filter)
          }
          if(method === "POST") {
            ctx.dispatch('fetchAlerts', {alerts: 'Проект добавлен в "Смотреть позже"', type: 'notify'})
          } else {
            ctx.dispatch('fetchAlerts', {alerts: 'Проект удален из "Смотреть позже"', type: 'notify'})
          }
          
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchProjects(ctx, filter) {
      let q = ''
      if (filter  && Object.keys(filter).length) {
        for (let key in filter) {
          let str = ''
          if (filter[key].length) {
            filter[key].forEach((i, ind) => {
              if (i) {
                str = ''
                str += `&${key}=${i}`
                q += str
              }

            })
          }
        }
        q = "?" + q
      }
      ctx.commit('updateProjects', null)
      return await this.$axios({
        method: 'GET',
        url: `/api/projects/${q}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateProjects', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchArchiveEventsFavorite(ctx, {method, id, filter,  favoritePage = false}) {
      return await this.$axios({
        method: method,
        url: `/api/archive-events/${id}/favorite/`,
      })
        .then(async (response) => {
          const result = response.data;
          if(!favoritePage) {
            ctx.dispatch('fetchArchiveEvents', filter)
          }
          if(method === "POST") {
            ctx.dispatch('fetchAlerts', {alerts: 'Проект добавлен в избранное', type: 'notify'})
          } else {
            ctx.dispatch('fetchAlerts', {alerts: 'Проект удален из избранного', type: 'notify'})
          }
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchArchiveEventsWatchLater(ctx, {method, id, filter,  favoritePage = false}) {
      return await this.$axios({
        method: method,
        url: `/api/archive-events/${id}/watch-later/`,
      })
        .then(async (response) => {
          const result = response.data;
          if(!favoritePage) {
            ctx.dispatch('fetchArchiveEvents', filter)
          }
          if(method === "POST") {
            ctx.dispatch('fetchAlerts', {alerts: 'Проект добавлен в "Смотреть позже"', type: 'notify'})
          } else {
            ctx.dispatch('fetchAlerts', {alerts: 'Проект удален из "Смотреть позже"', type: 'notify'})
          }
          
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchArchiveEvents(ctx, filter) {
      let q = ''
      if (filter && Object.keys(filter).length) {
        for (let key in filter) {
          let str = ''
          if (filter[key].length) {
            filter[key].forEach((i, ind) => {
              if (i) {
                str = ''
                str += `&${key}=${i}`
                q += str
              }

            })
          }
        }
        q = "?" + q
      }
      ctx.commit('updateArchiveEvents', null)
      return await this.$axios({
        method: 'GET',
        url: `/api/archive-events/${q}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateArchiveEvents', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchVideoMaterials(ctx, filter) {
      let q = ''
      if (filter  && Object.keys(filter).length) {
        for (let key in filter) {
          let str = ''
          if (filter[key].length) {
            filter[key].forEach((i, ind) => {
              if (i) {
                str = ''
                str += `&${key}=${i}`
                q += str
              }

            })
          }
        }
        q = "?" + q
      }
      ctx.commit('updateVideoList', null)
      return await this.$axios({
        method: 'GET',
        // headers: {
        //   Authorization: null,
        // },
        url: `/api/library/video/${q}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateVideoList', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchEpicentralMaterials(ctx, filter) {
      let q = ''
      if (filter  && Object.keys(filter).length) {
        for (let key in filter) {
          let str = ''
          if (filter[key].length) {
            filter[key].forEach((i, ind) => {
              if (i) {
                str = ''
                str += `&${key}=${i}`
                q += str
              }

            })
          }
        }
        q = "?" + q
      }
      ctx.commit('updateEpicentralList', null)
      return await this.$axios({
        method: 'GET',
        url: `/api/epicentral/materials/${q}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateEpicentralList', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchTextHistory(ctx, slug) {
      return await this.$axios({
        method: "POST",
        url: `/api/library/text/${slug}/view/`,
      })
        .then(async (response) => {
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchTextFavorite(ctx, {method, slug, filter,  favoritePage = false}) {
      return await this.$axios({
        method: method,
        url: `/api/library/text/${slug}/favorite/`,
      })
        .then(async (response) => {
          const result = response.data;
          if(!favoritePage) {
            ctx.dispatch('fetchTextMaterials', filter)
          }
          if(method === "POST") {
            ctx.dispatch('fetchAlerts', {alerts: 'Материал добавлен в избранное', type: 'notify'})
          } else {
            ctx.dispatch('fetchAlerts', {alerts: 'Материал удален из избранного', type: 'notify'})
          }
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchTextWatchLater(ctx, {method, slug, filter,  favoritePage = false}) {
      return await this.$axios({
        method: method,
        url: `/api/library/text/${slug}/watch-later/`,
      })
        .then(async (response) => {
          const result = response.data;
          if(!favoritePage) {
            ctx.dispatch('fetchTextMaterials', filter)
          }
          if(method === "POST") {
            ctx.dispatch('fetchAlerts', {alerts: 'Материал добавлен в "Смотреть позже"', type: 'notify'})
          } else {
            ctx.dispatch('fetchAlerts', {alerts: 'Материал удален из "Смотреть позже"', type: 'notify'})
          }
          
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchTextDetail(ctx, obj) {
      let params = ''
      if (obj.access) {
        params = `?access=${obj.access}`
      }
      return await this.$axios({
        method: 'GET',
        url: `/api/library/text/${obj.slug}/${params}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateTextDetail', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          if (error.request.status === 404) {
            ctx.commit('updateTextDetail', 404)
          } else {
            ctx.commit('updateTextDetail', false)
          }

          return false
        });
    },
    async fetchEpicentralDetail(ctx, obj) {
      let params = ''
      if (obj.access) {
        params = `?access=${obj.access}`
      }
      return await this.$axios({
        method: 'GET',
        url: `/api/epicentral/materials/${obj.slug}/${params}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateEpicentralDetail', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          if (error.request.status === 404) {
            ctx.commit('updateEpicentralDetail', 404)
          } else {
            ctx.commit('updateEpicentralDetail', false)
          }

          return false
        });
    },
    async fetchVideoHistory(ctx, slug) {
      return await this.$axios({
        method: "POST",
        url: `/api/library/video/${slug}/view/`,
      })
        .then(async (response) => {
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchVideoDetail(ctx, obj) {
      let params = ''
      if (obj.access) {
        params = `?access=${obj.access}`
      }
      return await this.$axios({
        method: 'GET',
        url: `/api/library/video/${obj.slug}/${params}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateVideoDetail', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          if (error.request.status === 404) {
            ctx.commit('updateVideoDetail', 404)
          }
          else {
            ctx.commit('updateVideoDetail', false)
          }
          return false
        });
    },
    async fetchVideoFavorite(ctx, {method, slug, filter,  favoritePage = false}) {
      return await this.$axios({
        method: method,
        url: `/api/library/video/${slug}/favorite/`,
      })
        .then(async (response) => {
          const result = response.data;
          if(!favoritePage) {
            ctx.dispatch('fetchVideoMaterials', filter)
          }
          if(method === "POST") {
            ctx.dispatch('fetchAlerts', {alerts: 'Видео добавлено в избранное', type: 'notify'})
          } else {
            ctx.dispatch('fetchAlerts', {alerts: 'Видео удалено из избранного', type: 'notify'})
          }
          
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchVideoWatchLater(ctx, {method, slug, filter,  favoritePage = false}) {
      return await this.$axios({
        method: method,
        url: `/api/library/video/${slug}/watch-later/`,
      })
        .then(async (response) => {
          const result = response.data;
          if(!favoritePage) {
            ctx.dispatch('fetchVideoMaterials', filter)
          }
          if(method === "POST") {
            ctx.dispatch('fetchAlerts', {alerts: 'Видео добавлено в "Смотреть позже"', type: 'notify'})
          } else {
            ctx.dispatch('fetchAlerts', {alerts: 'Видео удалено из "Смотреть позже"', type: 'notify'})
          }
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchPodcasts(ctx, filter) {
      let q = ''
      if (filter  && Object.keys(filter).length) {
        for (let key in filter) {
          let str = ''
          if (filter[key].length) {
            filter[key].forEach((i, ind) => {
              if (i) {
                str = ''
                str += `&${key}=${i}`
                q += str
              }

            })
          }
        }
        q = "?" + q
      }
      ctx.commit('updatePodcastsList', null)
      return await this.$axios({
        method: 'GET',
        // headers: {
        //   Authorization: null,
        // },
        url: `/api/library/podcast/${q}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updatePodcastsList', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchPodcastDetail(ctx, obj) {
      let params = ''
      if (obj.access) {
        params = `?access=${obj.access}`
      }
      return await this.$axios({
        method: 'GET',
        // headers: {
        //   Authorization: null,
        // },
        url: `/api/library/podcast/${obj.slug}/${params}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updatePodcastDetail', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          if (error.request.status === 404) {
            ctx.commit('updatePodcastDetail', 404)
          }
          else {
            ctx.commit('updatePodcastDetail', false)
          }
          return false
        });
    },
    async fetchPodcastFavorite(ctx, {method, slug, filter, favoritePage = false}) {
      return await this.$axios({
        method: method,
        url: `/api/library/podcast/${slug}/favorite/`,
      })
        .then(async (response) => {
          const result = response.data;
          if(!favoritePage) {
            ctx.dispatch('fetchPodcasts', filter)
          }
          if(method === "POST") {
            ctx.dispatch('fetchAlerts', {alerts: 'Подкаст добавлен в избранное', type: 'notify'})
          } else {
            ctx.dispatch('fetchAlerts', {alerts: 'Подкаст удален из избранного', type: 'notify'})
          }
         
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchPodcastWatchLater(ctx, {method, slug, filter, favoritePage = false}) {
      return await this.$axios({
        method: method,
        url: `/api/library/podcast/${slug}/watch-later/`,
      })
        .then(async (response) => {
          const result = response.data;
          if(!favoritePage) {
            ctx.dispatch('fetchPodcasts', filter)
          }
          if(method === "POST") {
            ctx.dispatch('fetchAlerts', {alerts: 'Подкаст добавлен в "Смотреть позже"', type: 'notify'})
          } else {
            ctx.dispatch('fetchAlerts', {alerts: 'Подкаст удален из "Смотреть позже"', type: 'notify'})
          }
          
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchPodcastHistory(ctx, slug) {
      return await this.$axios({
        method: "POST",
        url: `/api/library/podcast/${slug}/view/`,
      })
        .then(async (response) => {
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchTabs(ctx, slug) {
      return await this.$axios({
        method: "GET",
        url: `api/ne_po_sekretu/tabs/`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateTabs', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
  },
})