export default {
  state: {
    drugs: [],
    docs: null
  },
  getters: {
    Drugs: s => s.drugs,
    Docs: s => s.docs,
  },
  mutations: {
    updateDrugs(s, items) {
      s.drugs = items.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
    },
    updateDocs(s, items, add) {
      if(add) {
        s.docs += items
      } else {
        s.docs = items.slice(0,9)
      }
     
    },
  },
  actions: {
    async fetchDrugs(ctx) {
      return this.$axios({
        method: 'GET',
        url: `/api/medinfo/medications`,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          ctx.commit('updateDrugs', response.data)
          return true
        })
        .catch((error) => {
          // ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          // console.error(error);
          return false
        });
    },
    async fetchDocs(ctx, {id, q, o, lim = 5}) {
      return this.$axios({
        method: 'GET',
        url: `/api/medinfo/search/?query=${q}&offset=${o}&medication=${id}&limit=${lim}`,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          ctx.commit('updateDocs', response.data.result, !!o)
          return true
        })
        .catch((error) => {
          // ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          // console.error(error);
          return false
        });
    },
    async sendDoc(ctx, {id, email}) {
      let fd = new FormData()
      fd.append('email', email)
      return this.$axios({
        method: 'POST',
        url: `/api/medinfo/${id}/send/`,
        data: fd
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          ctx.dispatch('fetchAlerts', {alerts: `Документ отправлен на ${email}`, type: 'notify'})
          return true
        })
        .catch((error) => {
          ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          // console.error(error);
          return false
        });
    },
  },
}
