export default ({
  state: {
    materialsNosology: [],
    materialsDisease: [],
  },
  getters: {
    materials_therapeutic_areas: s => s.materialsNosology,
    materials_disease: s => s.materialsDisease,
  },
  mutations: {
    updateMatNos(s, nosology) {
      s.materialsNosology = nosology
    },
    updateMatDis(s, dis) {
      s.materialsDisease = dis
    },
  },
})