export default {
  state: {
    alerts: {},
    inputError: false
  },
  getters: {
    inputError: s => s.inputError,
    getAlerts: s => s.alerts, 
  },
  mutations: {
    setAlerts(s, alerts) {
      s.alerts = alerts
    },
    updateInputError(state, status) {
      state.inputError = status
    }
  },
  actions: {
    async fetchAlerts(ctx, { alerts, type }) {
      let obj = {
        type: type,
        alerts: alerts,
      }
      ctx.commit('setAlerts', obj)
    },
    async clearAlerts(ctx) {
      ctx.commit('setAlerts', {})
    },
    clearInputError(ctx) {
      ctx.commit('updateInputError',false)
    }
  },
}
