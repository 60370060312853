export default {
  state: {
    mapItems: [],
  },
  getters: {
    mapItems: s => s.mapItems,
  },
  mutations: {
    updateMapItems(s, items) {
      s.mapItems = items
    },
  },
  actions: {
    async fetchMapItems(ctx, region) {
      let q = region ? `?region=${region}` : ""
        return this.$axios({
          method: 'GET',
          url: `/api/geo/clinics/${q}`,
        })
          .then((response) => {
            const status = response.data.status;
            if (status >= 400) {
              return false
            }
            ctx.commit('updateMapItems', response.data)
            return true
          })
          .catch((error) => {
            // ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
            // console.error(error);
            return false
          });
    },
    clearSearch(ctx) {
      ctx.commit('updateSearchResults', { results: [], q: '' })
    }
  },
}
