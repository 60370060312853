import axios from "axios";
import Vue from 'vue'
import Vuex from 'vuex'
import store from '@/store'

axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_HOST}/`;
axios.defaults.withCredentials = true;
axios.defaults.headers.common.Authorization = localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null;




export default {
  async install() {
    Vue.prototype.$axios = axios
    Vuex.Store.prototype.$axios = axios
    function request(request) {
      const axiosSource = axios.CancelToken.source();
      request.cancelToken = axiosSource.token;
      store.dispatch("addRequest", {
        ...request,
        cancel: axiosSource.cancel,
      });
      return request;
    }

    function requestError() {
      store.commit("requestFailed");
    }
    await axios.interceptors.request.use(request, requestError);
    await axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (
          error &&
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry &&
          !error.config.url.includes("refresh")
        ) {
          localStorage.removeItem("token");
          delete axios.defaults.headers.common.Authorization;
          if (localStorage.getItem("refresh")) {
            originalRequest._retry = true;
            let fd = new FormData();
            fd.append("refresh", localStorage.getItem("refresh"));
            return await fetch(
              `${process.env.VUE_APP_BACKEND_HOST}/api/auth/sso-update-tokens`,
              {
                method: "POST",
                body: fd,
              }
            ).then(async (res) => {
              if (res.status === 200) {
                let result = await res.json();
                // 1) put token to LocalStorage
                localStorage.setItem("token", result.access_token);
                localStorage.setItem("refresh", result.refresh);
                // 2) Change Authorization header
                axios.defaults.headers.common.Authorization =
                  "Bearer " + result.access_token;
                originalRequest.headers.Authorization =
                  "Bearer " + result.access_token;
                // 3) return originalRequest object with Axios.
                // await this.getSession();
                await store.dispatch('getSession')
                return Promise.resolve(
                  await axios.request(originalRequest)
                );

              } else {
                delete axios.defaults.headers.common.Authorization;
                delete originalRequest.headers.Authorization;
                localStorage.removeItem("token");
                localStorage.removeItem("refresh");

                // window.location.reload();
                
                // if (this.$route.meta.auth) {
                //   this.$router.push({ name: "Login" });
                // }
                if (window.location.search.includes('access')) {
                  return Promise.resolve(
                    await axios.request(originalRequest)
                  );
                  // window.location.reload();
                }
                // window.location.reload();
                return Promise.reject(error);
              }
            });
          } else {
            localStorage.removeItem("token");
            delete axios.defaults.headers.common.Authorization;
            delete originalRequest.headers.Authorization;
            originalRequest._retry = true;
            if (window.location.search.includes('access')) {
              return Promise.resolve(
                await axios.request(originalRequest)
              );
              // window.location.reload();
            }
            // window.location.reload();
            return Promise.reject(error);
            // if (this.$route.meta.auth) {
            // this.$router.push({ name: "Login" });
            // } //, query:  { back_url: this.$route.path}
          }
        } else if (
          error &&
          error.response &&
          error.response.status === 401 &&
          originalRequest._retry
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          // if (this.$route.meta.auth) {
          //   this.$router.push({ name: "Login" });
          // }
        }
        // window.location.reload();
        return Promise.reject(error);
      }
    );
  }
};