export default ({
    state: {
        rules: {
            isRequired: (v, e) => !!v || e,
            isCorrectEmail: (v, e) => /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,5})$/.test(v) || (typeof e !== 'undefined' ? e : "Некорректный email"),
            isCorrectPhone: (v, e) => /^((8|\+7)[\- ]?)?(\(?\d{3,4}\)?[\- ]?)?[\d\- ]{10}$/.test(v) || (typeof e !== 'undefined' ? e : "Некорректный номер"),
            onlyLatin: (v, e) => /^.[^А-Яа-я]*$/.test(v) || e,
            digitsReq: (v, e) => /^(?=.*?[0-9])/.test(v) || e,
            minLength: (v, e, l = 8) => (!!v & v.length >= l) || e,
        }
    },
    getters: {
        Rules(state) {
            return state.rules;
        }
    },
})