export default {
  state: {
    restoreSmsConfirm: null,
  },
  getters: {
    restoreSmsConfirmed: s => s.restoreSmsConfirm,
  },
  mutations: {
    updateRestoreSmsConfirmed(state, status) {
      state.restoreSmsConfirm = status
    },
  },
  actions: {
    async restorePasswordStart(ctx, email) {
        const fd = new FormData();
        fd.append('kind', 'email');
        fd.append('auth_data', email);
  
        return this.$axios({
          method: 'POST',
          url: '/api/auth/restore-password/start/',
          data: fd,
        })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          return true
        })
        .catch((error) => {
          ctx.dispatch('fetchAlerts', { alerts: 'Произошла ошибка', type: 'error' })
          console.error(error);
          return false
        });
    },
    restorePhoneResend(ctx) {
      return this.$axios({
        method: 'POST',
        url: '/api/auth/restore-password/resend/',
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          return true
        })
        .catch((error) => {
          ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          console.error(error);
          return false
        });
    },
    restorePhoneCode(ctx, code) {
      const fd = new FormData();
      fd.append('pin', code);

      return this.$axios({
        method: 'POST',
        url: '/api/auth/restore-password/verify/',
        data: fd,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            ctx.commit('updateRestoreSmsConfirmed', false)
            return false
          }
          ctx.commit('updateRestoreSmsConfirmed', true);
          const result = response.data.restore_id;
          return true
        })
        .catch((error) => {
          ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          console.error(error);
          ctx.commit('updateRestoreSmsConfirmed', false)
          return false
        });
    },
    restorePassword(ctx, passData) {
      ctx.commit('updateInputError', false)
      const fd = new FormData();
      for (let data in passData) {
        fd.append(data, passData[data])
      }

      return this.$axios({
        method: 'POST',
        url: '/api/auth/restore-password/change/',
        data: fd,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          return true
        })
        .catch((error) => {
          let response = JSON.parse(error.request.response)
          if(response && response.non_field_errors) {
            ctx.commit('updateInputError', response.non_field_errors[0])
          } else {
            ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          }
          console.error(error);
          return false
        });
    },
  },
}
