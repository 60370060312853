export default {
  state: {
    educations: [],
    education: 'loading',
    educationBlock: {}
  },
  getters: {
    EducationList: s => s.educations, 
    EducationDetail: s => s.education, 
    EducationBlock: s => s.educationBlock, 
  },
  mutations: {
    updateEducationList(s, educations) {
      s.educations = educations
    },
    updateEducationDetail(s, education) {
      s.education = education
    },
    updateEducationTests(s, tests) {
      if(s.education.passed_tests) {
        s.education.passed_tests = tests.passed_tests
      }

      if(s.education.tests_count) {
        s.education.tests_count = tests.tests_count
      }
      
    },
    updateEducationBlock(s, educationBlock) {
      s.educationBlock = educationBlock
    },
    checkedEducationBlock(s, state) {
      s.educationBlock.is_completed = state
    },
    updateEducationDetailModules(s, modules) {
      s.education.modules = modules
    },
  },
  actions: {
    async fetchEducationList(ctx, filter) {
      let q = ''
      if (filter  && Object.keys(filter).length) {
        for (let key in filter) {
          let str = ''
          if (filter[key].length) {
            filter[key].forEach((i, ind) => {
              if (i) {
                str = ''
                str += `&${key}=${i}`
                q += str
              }

            })
          }
        }
        q = "?" + q
      }
      ctx.commit('updateEducationList', null)
      return await this.$axios({
        method: 'GET',
        url: `/api/education/trenings/${q}`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateEducationList', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        });
    },
    async fetchEducationDetail(ctx, obj) {
      // ctx.commit('updateEducationDetail', {})
      let params = ''
      if (obj.access) {
        params = `?access=${obj.access}`
      }
      return await this.$axios({
        method: 'GET',
        // headers: {
        //   Authorization: null,
        // },
        url: `/api/education/trenings/${obj.slug}/${params}`,
      })
        .then(async (response) => {
          const result = response.data;
          if(obj.update_only_tests) {
            ctx.commit('updateEducationTests', {passed_tests: result.passed_tests, tests_count: result.tests_count})
          } else {
            ctx.commit('updateEducationDetail', result)
          }
          
          return true
        })
        .catch((error) => {
          console.error(error)
          if (error && error.request && error.request.status === 404) {
            ctx.commit('updateEducationDetail', 404)
          } else if (error && error.request && error.request.status === 403) {
            ctx.commit('updateEducationDetail', 403)
          }  
          else if(error.code !== 'ERR_CANCELED'){
            ctx.commit('updateEducationDetail', false)
          }

          return false
        });
    },
    async fetchEducationBlock(ctx, obj) {
      ctx.commit('updateEducationBlock', {})
      return await this.$axios({
        method: 'GET',
        // headers: {
        //   Authorization: null,
        // },
        url: `/api/education/modules/${obj.module_slug}/blocks/${obj.block_slug}/`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateEducationBlock', result)
          // ctx.dispatch('fetchEducationModules', {slug: obj.training_slug})
          return true
        })
        .catch((error) => {
          console.error(error)
          if (error && error.request && error.request.status === 404) {
            // ctx.commit('updateEducationDetail', 404)
          } else if(error.code !== 'ERR_CANCELED'){
            // ctx.commit('updateEducationDetail', false)
          }

          return false
        });
    },
    async fetchEducationModules(ctx, obj) {
      return await this.$axios({
        method: 'GET',
        // headers: {
        //   Authorization: null,
        // },
        url: `/api/education/trenings/${obj.slug}/modules/`,
      })
        .then(async (response) => {
          const result = response.data;
          ctx.commit('updateEducationDetailModules', result)
       
          return true
        })
        .catch((error) => {
          console.error(error)
          if (error && error.request && error.request.status === 404) {
            // ctx.commit('updateEducationDetail', 404)
          } else if(error.code !== 'ERR_CANCELED'){
            // ctx.commit('updateEducationDetail', false)
          }

          return false
        });
    },
  },
}
