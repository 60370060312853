export default ({
  state: {
    date_before: null,
    date_after: null,
    nosology: [],
    type: [],
    types: [],
    disease: [],
    sort: [],
    material_type: []
  },
  getters: {
    date_before: s => s.date_before,
    date_after: s => s.date_after,
    therapeutic_areas: s => s.nosology,
    type: s => s.type,
    types: s => s.types,
    disease: s => s.disease,
    sort: s => s.sort,
    material_type: s => s.material_type,
  },
  mutations: {
    updateDateBefore(s, date) {
      s.date_before = date
    },
    updateDateAfter(s, date) {
      s.date_after = date
    },
    updateNos(s, nosology) {
      s.nosology = nosology
    },
    updateType(s, type) {
      s.type = type
    },
    updateTypes(s, types) {
      s.types = types
    },
    updateDis(s, dis) {
      s.disease = dis
    },
    updateSort(s, sort) {
      s.sort = sort
    },
    updateEpicentralFormat(s, format) {
      s.material_type = format
    },
  },
  actions: {
    clearFilter(ctx, field) {
      if (field) {
        switch (field) {
          case "therapeutic_areas":
            ctx.commit('updateNos', []);
            break;
          case "disease":
            ctx.commit('updateDis', []);
            break;
          case "type":
            ctx.commit('updateType', []);
            break;
          case "types":
            ctx.commit('updateTypes', []);
            break;
          case "sort":
            ctx.commit('updateSort', []);
            break;
          case "epicentralFormat":
            ctx.commit('updateEpicentralFormat', []);
            break;
        }
      } else {
        ctx.commit('updateDateBefore', null)
        ctx.commit('updateDateAfter', null)
        ctx.commit('updateNos', [])
        ctx.commit('updateType', [])
        ctx.commit('updateTypes', [])
        ctx.commit('updateDis', [])
        ctx.commit('updateSort', [])
        ctx.commit('updateEpicentralFormat', [])
      }

    },
    clearDates(ctx) {
      ctx.commit('updateDateBefore', null)
      ctx.commit('updateDateAfter', null)
    }
  },
})